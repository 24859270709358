/* eslint-disable max-len */
import { IInputError } from '@/components/layout/auth-layout'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export interface IAuthInput {
  id: string
  name: string
  value: string | any
  required: boolean
  type: string
  title: string
  error?: IInputError | null
  placeholder: string
  helperText: string
  onChange: any
  onFocus?: any
  showInputAdornment?: boolean
  onHandleClickInputAdornment?: any
  onHandleMouseInputAdornment?: any
  onKeyPress?: any
  disabled?: boolean
}

function EndAdornment({ name, onHandleClickInputAdornment, onHandleMouseInputAdornment, showInputAdornment }: any) {
  return (
    <InputAdornment position="end">
      {onHandleClickInputAdornment && (
        <>
          <IconButton
            aria-label={`"toggle ${name} visibility"`}
            onClick={onHandleClickInputAdornment}
            onMouseDown={onHandleMouseInputAdornment}
            edge="end"
          >
            {showInputAdornment ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </>
      )}
    </InputAdornment>
  )
}

export default function AuthInput({
  required,
  type,
  title,
  id,
  name,
  value,
  error,
  placeholder,
  onChange,
  helperText,
  showInputAdornment = false,
  onHandleClickInputAdornment = null,
  onHandleMouseInputAdornment = null,
  onKeyPress = null,
  disabled,
}: IAuthInput) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={0}
      sx={{ width: '100%' }}
    >
      <Typography
        component="small"
        sx={{
          width: '100%',
          fontSize: 14,
          fontWeight: 400,
          color: 'text.primary',
        }}
      >
        {title}
      </Typography>
      {typeof value === 'string' && (
        <OutlinedInput
          required={required}
          fullWidth
          id={id}
          name={name}
          sx={{ width: '100%', my: 0.5 }}
          type={type}
          value={value}
          error={!!error}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          endAdornment={
            onHandleClickInputAdornment && (
              <EndAdornment
                name={name}
                onHandleClickInputAdornment={onHandleClickInputAdornment}
                onHandleMouseInputAdornment={onHandleMouseInputAdornment}
                showInputAdornment={showInputAdornment}
              />
            )
          }
        />
      )}
      {typeof value !== 'string' && value}
      <Typography
        component="small"
        sx={{
          width: '100%',
          fontSize: 12,
          fontWeight: 300,
          color: `${error ? 'error.main' : 'text.primary'}`,
        }}
      >
        {error ? error?.message : helperText}
      </Typography>
    </Grid>
  )
}
